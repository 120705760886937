import PermitDetails from "./PermitDetails.vue"

const config = {
	mainRoute: {
		name: "PermitDetails",
		path: "/permitdetail",
		component: PermitDetails
	}
}

export { config }