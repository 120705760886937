/* eslint-disable */
import { createApp } from 'vue'
import App from './App.vue'
import { createPinia } from 'pinia'

import { router } from './router/index'
import './assets/css/index.css'

// import global components
import TwFullScreenLoading from '@/components/Loading/FullScreenLoading.vue'
import TwLoading from '@/components/Loading/TwLoading.vue'
import TwModal from '@/components/Modals/TwModal.vue'
import TwNotification from '@/components/Notifications/TwNotification.vue'
import TwSelect from '@/components/SelectMenus/TwSelect.vue'
import TwTable from '@/components/Tables/TwTable.vue'
import TwTableColumn from '@/components/Tables/TwTableColumn.vue'
import TwTableDropdown from '@/components/Tables/TwTableDropdown.vue'
import TwTableHeader from '@/components/Tables/TwTableHeader.vue'
import TwTableRow from '@/components/Tables/TwTableRow.vue'
import GoogleMapsAutoComplete from './components/InputGroups/GoogleMapsAutoComplete.vue'

/* Badge */
import VBadge from '@/components/Badges/VBadge.vue'
/* Button */
import VButton from '@/components/Buttons/VButton.vue'
/* Checkbox */
import VCheckbox from '@/components/Checkboxes/VCheckbox.vue'
import VCheckboxField from '@/components/Checkboxes/VCheckboxField.vue'
import VCheckboxGroup from '@/components/Checkboxes/VCheckboxGroup.vue'
/* Dropdown */
import VDropdown from '@/components/Dropdowns/VDropdown.vue'
import VDropdownButton from '@/components/Dropdowns/VDropdownButton.vue'
import VDropdownDivider from '@/components/Dropdowns/VDropdownDivider.vue'
import VDropdownItem from '@/components/Dropdowns/VDropdownItem.vue'
import VDropdownLabel from '@/components/Dropdowns/VDropdownLabel.vue'
import VDropdownMenu from '@/components/Dropdowns/VDropdownMenu.vue'
/* Input */
import VInput from '@/components/InputGroups/VInput.vue'
import VInputGroup from '@/components/InputGroups/VInputGroup.vue'
/* Fieldset */
import VDescription from '@/components/Fieldsets/VDescription.vue'
import VErrorMessage from '@/components/Fieldsets/VErrorMessage.vue'
import VField from '@/components/Fieldsets/VField.vue'
import VFieldset from '@/components/Fieldsets/VFieldset.vue'
import VFieldGroup from '@/components/Fieldsets/VFieldGroup.vue'
import VLabel from '@/components/Fieldsets/VLabel.vue'
import VLegend from '@/components/Fieldsets/VLegend.vue'
/* Select */
import VSelect from '@/components/SelectMenus/VSelect.vue'
/* Switch */
import VSwitch from '@/components/Switches/VSwitch.vue'
import VSwitchField from '@/components/Switches/VSwitchField.vue'
import VSwitchGroup from '@/components/Switches/VSwitchGroup.vue'

// import icons from Tailwind's Hero Icons
// Solid icons
import {
	ExclamationCircleIcon,
	MagnifyingGlassIcon
} from '@heroicons/vue/24/solid'

// Outline icons
import {
	XMarkIcon
} from '@heroicons/vue/24/outline'

// Mini icons
import {
	ChevronDownIcon as ChevronDownIconMini,
	FunnelIcon as FunnelIconMini
} from '@heroicons/vue/20/solid'

// configure amplify
import { Amplify } from 'aws-amplify'
import awsconfig from './aws-exports'

Amplify.configure(awsconfig)

Amplify.configure({
	API: {
		endpoints: [
		  {
			name: "fmDataApi",
			endpoint: process.env.VUE_APP_API_URL,
		  }
		],
	}
})
// extend types
declare module '@vue/runtime-core' {
	interface ComponentCustomProperties {
		$filters: {
			usd:(amount: number) => string,
			friendlyDate:(date: string) => string
		}
	}
}

const pinia = createPinia()
const app = createApp(App)

// Set up global filters
app.config.globalProperties.$filters = {
	usd(amount: number) {
        const formatter = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'})
        return formatter.format(amount)
    },
	friendlyDate(date: string) {
		if(!date) return ''
		const formatter = new Intl.DateTimeFormat('en-US', {dateStyle: 'long'})
		return formatter.format(new Date(date))
	}
}

// Register icons globally
app.component('ChevronDownIconMini', ChevronDownIconMini)
app.component('ExclamationCircleIcon', ExclamationCircleIcon)
app.component('FunnelIconMini', FunnelIconMini)
app.component('MagnifyingGlassIcon', MagnifyingGlassIcon)
app.component('XMarkIcon', XMarkIcon)

// Register global components
app.component("tw-full-screen-loading", TwFullScreenLoading)
app.component("tw-loading", TwLoading)
app.component("tw-modal", TwModal)
app.component("tw-notification", TwNotification)
app.component("tw-table", TwTable)
app.component("tw-table-column", TwTableColumn)
app.component("tw-table-dropdown", TwTableDropdown)
app.component("tw-table-header", TwTableHeader)
app.component("tw-table-row", TwTableRow)
app.component("tw-select", TwSelect)
app.component("google-maps-autocomplete", GoogleMapsAutoComplete)

/* Badge */
app.component("v-badge", VBadge)
/* Button */
app.component("v-button", VButton)
/* Checkbox */
app.component("v-checkbox", VCheckbox)
app.component("v-checkbox-field", VCheckboxField)
app.component("v-checkbox-group", VCheckboxGroup)
/* Dropdown */
app.component("v-dropdown", VDropdown)
app.component("v-dropdown-button", VDropdownButton)
app.component("v-dropdown-divider", VDropdownDivider)
app.component("v-dropdown-item", VDropdownItem)
app.component("v-dropdown-label", VDropdownLabel)
app.component("v-dropdown-menu", VDropdownMenu)
/* Fieldset */
app.component("v-description", VDescription)
app.component("v-error-message", VErrorMessage)
app.component("v-field", VField)
app.component("v-fieldset", VFieldset)
app.component("v-field-group", VFieldGroup)
app.component("v-label", VLabel)
app.component("v-legend", VLegend)
/* Input*/
app.component("v-input", VInput)
app.component("v-input-group", VInputGroup)
/* Select */
app.component("v-select", VSelect)
/* Switch */
app.component("v-switch", VSwitch)
app.component("v-switch-field", VSwitchField)
app.component("v-switch-group", VSwitchGroup)

app.use(pinia)
app.use(router)
app.mount('#app')