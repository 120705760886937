import ApplicationList from "./ApplicationList.vue"

const config = {
	mainRoute: {
		name: "ApplicationList",
		path: "/applicationlist",
		component: ApplicationList
	}
}

export { config }