<template>
	<Menu
		as="div"
		class="relative inline-block text-left">
		<div>
			<MenuButton class="flex items-center rounded-full text-gray-400 hover:text-brand-500 focus:outline-none focus:ring-2 focus:ring-brand-500 focus:ring-offset-2 focus:ring-offset-gray-100">
				<span class="sr-only">Open options</span>
				<span
					v-if="$slots.icon"
					class="h-5 w-5"
					aria-hidden="true"><slot name="icon" /></span>
			</MenuButton>
		</div>

		<transition
			enter-active-class="transition ease-out duration-100"
			enter-from-class="transform opacity-0 scale-95"
			enter-to-class="transform opacity-100 scale-100"
			leave-active-class="transition ease-in duration-75"
			leave-from-class="transform opacity-100 scale-100"
			leave-to-class="transform opacity-0 scale-95">
			<MenuItems
				:class="{'left-0': position == 'left', 'right-0': position == 'right'}"				 
				class="absolute z-30 mt-2 w-64 divide-y divide-gray-100 origin-top-right backdrop-blur backdrop-filter bg-opacity-75 rounded-md bg-gray-100 border border-usgaGray shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none overflow-auto">
				<div
					class="py-1">
					<MenuItem
						v-slot="{ active }"
						v-for="item in items"
						:key="item">
						<div
							:class="[active ? 'bg-brand-500 text-white cursor-pointer' : 'text-gray-700', 'font-normal rounded-md group flex items-center px-2 py-1 text-sm mx-1']"
							@click="!selectedTableRows.length>0 ? item.method(data) : item.method(dataArray(selectedTableRows))">							
							<component
								v-if="item.icon"
								:is="item.icon"
								class="h-5 w-5 font-semibold text-gray-400 group-hover:text-white"
								aria-hidden="true" />
							{{ item.label }}
						</div>
					</MenuItem>
				</div>
			</MenuItems>
		</transition>
	</Menu>
</template>

<script setup>
	import {
		defineProps
	} from 'vue'
	import {
		Menu,
		MenuButton,
		MenuItem,
		MenuItems
	} from '@headlessui/vue'

	
	// eslint-disable-next-line no-unused-vars
	const props = defineProps({
		items: {
			type: Array,
			default: () => {return []}
		},
		position:{
			type: String,
			default: ""
		},
		data:{
			type: Object,
			default: () => {return}
		},
		selectedTableRows:{
			type: Array,
			default: () => {return []}
		}
	})

	function dataArray(index) {

		let arr = []

		index.forEach(element => {
			arr.push(props.data[element])
		})

		return arr
	}

	
</script>