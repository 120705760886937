import {
	API
} from 'aws-amplify'


export default async function (httpMethod: string, apiName: string, path: string, body = {}) {

	let payload = {}

	// check for logged in user

    payload = {
        body: body
    }


    let result
    try {
		// run query or mutation
        switch(httpMethod) {
            case 'post':
                result = await API.post(apiName, path, payload)
                break
            case 'get':
                result = await API.get(apiName, path, payload)
                break
            case 'put':
                result = await API.put(apiName, path, payload)
                break
            case 'delet':
                result = await API.del(apiName, path, payload)
                break
            default:
                throw 'Unsupported http method'
        }
    }
    catch(err) {
		console.error(err)
		// if logged out, refresh token or log out
		result = err
		throw err
    }

    return result
}