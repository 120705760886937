import ApplicationForm from "./ApplicationForm.vue"

const config = {
	mainRoute: {
		name: "ApplicationForm",
		path: "/application",
		component: ApplicationForm
	}
}

export { config }