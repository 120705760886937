import {
	createRouter,
	createWebHistory
} from 'vue-router'
import {
	Route
} from '@/types' // must be imported since file contains an export statement


interface Query {
	[ k: string ]: string
}
// Import all of the resource routes files
function loadRoutes() {
	const context = require.context('@/modules', true, /routes.ts$/i)
	return context.keys()
		.map(context) // import module
		.map((m: any) => m.route) // get `default` export from each resolved module
}

// Define the routes
const routes: Route[] = []
const resourceRoutes = loadRoutes()

resourceRoutes.forEach((moduleRoutes) => {
	moduleRoutes.forEach((route: Route) => {
		routes.push(route)
	})
})

// Create the router instance and pass the `routes` option
export const router = createRouter({
	history: createWebHistory(), // use history mode
	routes,
	scrollBehavior() {
		// always scroll to top
		return { top: 0 }
	}
})

router.beforeEach((to, from, next) => {
	// Check if the URL contains a hash
	
	if (to.hash) {
		const queryString = to?.hash?.substring(1)?.split("?")?.[1]
		const queryParams = queryString?.split("&")
		const query: Query  = {}
		queryParams?.forEach((param) => {
			const [key, val] = param.split("=")
			query[key] = val
		})
		// Redirect to the same route without the hash
		next({ path: to.hash.substring(1), query: query, hash: '' })
	}
	else {
		// Continue navigation normally
		next()
	}
})