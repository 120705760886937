import LandingPage from "./LandingPage.vue"

const config = {
	mainRoute: {
		name: "LandingPage",
		path: "/",
		component: LandingPage
	}
}

export { config }